@font-face {
  font-family: "Tiempos Headline";
  src: local("Tiempos Headline"), url("./fonts/TiemposHeadlineWeb-Bold.woff") format("woff");
}

body {
  margin: 0;
  font-family: "Nunito Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f6f9ff;
  color: #141B24;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
}

.section-title {
  font-family: "Tiempos Headline", sans-serif;
  letter-spacing: -0.02em;
}

.border, .border-bottom {
  border-color: #E8EDF5!important;
}

.rounded {
  border-radius: 2px !important;
}

.btn-snappr {
  font-weight: 700;
  border-radius: 2px;
  background-color: #141b24;
  border-color: #141b24;
  color: white;
  padding: 14px 25px;
}

.btn-snappr:hover {
  background-color: #454F5C;
  border-color: #454F5C;
  color: white;
}

.btn-snappr:focus {
  box-shadow: 0 0 0 0.2rem rgba(20, 27, 36, 0.2);
}

.btn-snappr img {
  margin-top: -2px;
  margin-left: 12px;
}

.nav-link {
  padding-right: 15px!important;
  padding-left: 15px!important;
  color: #454f5c!important;
  font-weight: 700;
}